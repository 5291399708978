


















import { Component, Mixins, Prop } from 'vue-property-decorator'

import {
  ContainerWidth
} from '../../../../../atoms'

import {
  MosaicModuleAddon, MosaicModuleAddonSize,
  MosaicModuleAddonType,
  MosaicModuleBasicVersionContent,
  MosaicModuleVersion
} from '../../../Mosaic.contracts'
import { AsyncComponent } from 'vue'
import { MosaicModuleAddonTypeUiComponentRegistry } from '../../../Mosaic.config'
import { log } from '../../../../../../support'
import { Inject, UseNonce } from '../../../../../../extensions'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { ResolvesRelatedAsync } from '../../../../../../services'

@Component<Basic>({
  name: 'Basic',
  mounted () {
    this.calcuateRowHeight()

    window.addEventListener('resize', this.calcuateRowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.calcuateRowHeight)
  }
})
export class Basic extends Mixins<UseNonce>(UseNonce) {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync

  @Prop({ type: Array, required: true })
  public readonly addons!: MosaicModuleBasicVersionContent['addons']

  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: MosaicModuleBasicVersionContent['containerWidth']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<MosaicModuleBasicVersionContent>['hasBottomSpacing']

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  public readonly version = MosaicModuleVersion

  public get style (): string {
    return `
      #${this.htmlId} {
        --paddingTop: ${this.rowHeight}px;
      }
    `
  }

  public getAddonComponent (addonType: MosaicModuleAddonType): AsyncComponent | undefined {
    const component = MosaicModuleAddonTypeUiComponentRegistry[addonType]

    if (typeof component === 'undefined') {
      log(`MosaicModuleUiPresentation.getAddonComponent(): There's no component binding for the passed-in [${addonType}] MosaicModuleAddonType!`, 'warn')
      return
    }

    return component
  }

  public getAddonClassnames (addon: MosaicModuleAddon): string[] {
    return [
      addon.type,
      addon.size === MosaicModuleAddonSize.Large ? 'col-12 col-md-12' : '',
      addon.size === MosaicModuleAddonSize.Medium ? 'col-12 col-md-8' : '',
      addon.size === MosaicModuleAddonSize.Small ? 'col-12 col-md-4' : ''
    ]
  }

  public calcuateRowHeight (): void {
    this.rowHeight = this.getRowHeight()
  }

  public rowHeight: number = 0

  private getRowHeight () {
    const rowRef = this.$refs.rowRef as Element

    if (rowRef) {
      const rowWidth = rowRef.getClientRects()[0].width
      const width = rowWidth / 3 - 30
      const height = width * 1.5
      return height
    }

    return 0
  }
}

export default Basic
