













import { Component, Inject } from 'vue-property-decorator'

import {
  log
} from '../../../../support'
import {
  isUnresolvedLink,
  LinkWithLabel,
  REGISTRY_RESPONSE_INJECTION_KEY,
  RegistryResponse
} from '../../../../contracts'

import { AbstractModuleUi } from '../../_abstract'

import {
  MosaicModule,
  MosaicModuleAddon,
  MosaicModuleAddonType,
  MosaicModuleVersion,
  ResolvedMosaicModuleAddon
} from '../Mosaic.contracts'
import { EmbedAddon, ImageAddon, ResolvedEmbedAddon, ResolvedImageAddon } from '../addons'

@Component<MosaicModuleUi>({
  name: 'MosaicModuleUi',
  components: {
    MosaicModuleUiPresentation: () => import(
      /* webpackChunkName: "frame" */
      './Mosaic.ui.presentation.vue'
    )
  }
})
export class MosaicModuleUi extends AbstractModuleUi<MosaicModule> {
  @Inject({ from: REGISTRY_RESPONSE_INJECTION_KEY, default: null })
  private readonly registryResponse!: RegistryResponse | null

  public addons: MosaicModuleAddon[] = []

  protected versionEnum = MosaicModuleVersion

  public async fetchRelated (): Promise<void> {
    await Promise.allSettled([
      this.fetchAddons()
    ])
  }

  private async fetchAddons (): Promise<void> {
    this.addons = await this.resolveAddons(this.data.content.addons)
  }

  private async resolveAddons (unresolvedAddons: MosaicModuleAddon[]): Promise<ResolvedMosaicModuleAddon[]> {
    const possibleAddons: PromiseSettledResult<ResolvedMosaicModuleAddon>[] =
      await Promise.allSettled(unresolvedAddons.map(addon => {
        return new Promise<ResolvedMosaicModuleAddon>((resolve, reject) => {
          switch (addon.type) {
            case MosaicModuleAddonType.Image: {
              this.resolveLinkAddon(addon as ImageAddon)
                .then(resolvedAddon => { resolve(resolvedAddon) })
                .catch(error => {
                  const message: string = 'MosaicModuleUi.resolveAddons(): Failed to resolve the related data for the following addon:'
                  log([message, addon, error], 'error')
                  reject(error)
                })
              break
            }

            case MosaicModuleAddonType.Embed: {
              this.resolveEmbedAddon(addon as EmbedAddon)
                .then(resolvedAddon => { resolve(resolvedAddon) })
                .catch(error => {
                  const message: string = 'MosaicModuleUi.resolveAddons(): Failed to resolve the related data for the following addon:'
                  log([message, addon, error], 'error')
                  reject(error)
                })
              break
            }

            default: {
              resolve(addon as ResolvedMosaicModuleAddon)
            }
          }
        })
      }))

    const resolvedAddons: ResolvedMosaicModuleAddon[] = []

    possibleAddons.forEach(addon => {
      if (addon.status === 'fulfilled') {
        resolvedAddons.push(addon.value)
      }
    })

    return resolvedAddons
  }

  private async resolveLinkAddon (unresolvedAddon: ImageAddon): Promise<ResolvedImageAddon> {
    if (!isUnresolvedLink(unresolvedAddon.link)) {
      return unresolvedAddon as ResolvedImageAddon
    }

    const resolvedLink: LinkWithLabel = await this.fetchLink(unresolvedAddon.link)

    return {
      ...unresolvedAddon,
      link: resolvedLink
    }
  }

  private async resolveEmbedAddon (unresolvedAddon: EmbedAddon): Promise<ResolvedEmbedAddon> {
    if (!isUnresolvedLink(unresolvedAddon.link)) {
      return unresolvedAddon as ResolvedEmbedAddon
    }

    const resolvedLink: LinkWithLabel = await this.fetchLink(unresolvedAddon.link)

    return {
      ...unresolvedAddon,
      link: resolvedLink
    }
  }
}

export default MosaicModuleUi
