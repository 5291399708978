import { render, staticRenderFns } from "./Basic.vue?vue&type=template&id=cf0faa4c&"
import script from "./Basic.vue?vue&type=script&lang=ts&"
export * from "./Basic.vue?vue&type=script&lang=ts&"
import style0 from "./Basic.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports